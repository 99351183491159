import React from 'react'

const LocationIcon = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C10.067 6 8.5 7.567 8.5 9.5C8.5 11.433 10.067 13 12 13C13.933 13 15.5 11.433 15.5 9.5C15.5 7.567 13.933 6 12 6ZM10.5 9.5C10.5 8.67157 11.1716 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5C13.5 10.3284 12.8284 11 12 11C11.1716 11 10.5 10.3284 10.5 9.5Z"
      // fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 9.5C18.5 12.2819 16.1353 15.4937 12.8014 19.9329C12.401 20.466 11.599 20.466 11.1986 19.9329C7.86468 15.4937 5.5 12.2819 5.5 9.5C5.5 5.91015 8.41015 3 12 3C15.5899 3 18.5 5.91015 18.5 9.5ZM16.5 9.5C16.5 10.3479 16.1305 11.4675 15.1791 13.0775C14.392 14.4094 13.317 15.9009 12 17.6666C10.683 15.9009 9.60799 14.4094 8.82091 13.0775C7.86951 11.4675 7.5 10.3479 7.5 9.5C7.5 7.01472 9.51472 5 12 5C14.4853 5 16.5 7.01472 16.5 9.5Z"
      // fill="#606060"
    />
  </svg>
)

export default LocationIcon

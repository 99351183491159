import React, { Ref, useCallback, useEffect, useRef, useState } from 'react'
import { Clusterer, Map, Placemark, YMaps, YMapsProps } from 'react-yandex-maps'

import { client } from '../../../api'
import css from '../../../App.module.css'
import pin from '../../../assets/img/pin.png'
import { MOSCOW_COORDS, Y_KEY } from '../../../config'
import { useAllPoints } from '../../../hooks/useAllPoints'
import { Point } from '../../../types/point'
import { Service } from '../../../types/service'
import { getCity, setCity } from '../../../utils/cityStorage'
import { getArrayPoints } from '../../../utils/getArrayPoints'
import { getCities, getPointsByName } from '../../../utils/getCities'
import { getFilterByService } from '../../../utils/getFilterByService'
import { getPointTypeIndex } from '../../../utils/getPointTypeIndex'
import { getServices } from '../../../utils/getServices'
import { isSamePoint, isVisiblePoint } from '../../../utils/pointsCheck'
import { Error } from '../Error'
import { RadioBox } from '../RadioBox'
import { SearchBlock } from '../SearchBlock'
import { ServiceCard } from '../ServiceCard'
import { ServicesBlock } from '../ServicesBlock'
import sidebar from '../Sidebar/sidebar.module.css'
import { groupTemplate, pinTemplate } from './templates'

export const YandexMap = (): JSX.Element => {
  const [state, setState] = useState<{
    iconContentLayout: any[]
    clusterIconContentLayout: any[]
    chosenIconContentLayout: any
  }>({
    iconContentLayout: [],
    clusterIconContentLayout: [],
    chosenIconContentLayout: null
  })
  const [ymaps, setYmaps] = useState<YMapsProps | undefined>()
  const [bounds, setBounds] = useState()
  const [errors, setErrors] = useState({ geo: '', points: '' })
  const [loaded, setLoaded] = useState(false)
  const [query, setQuery] = useState('')
  const [city, setCity] = useState<undefined | string>(undefined)
  const [geoStatus, setGeoStatus] = useState(false)
  const [points, setPoints] = useState<Point[]>([])
  const [point, setPoint] = useState<Point | undefined>()
  const [services, setServices] = useState<Service[]>([])
  const [checked, setChecked] = useState<boolean[]>([])
  const [searchType, setSearchType] = useState('city')
  const [searchedByName, setSearchedByName] = useState(false)

  const allPoints = useAllPoints()
  const { iconContentLayout, clusterIconContentLayout, chosenIconContentLayout } = state

  const mapRef = useRef<Ref<any>>()
  const setMapRef = useCallback((instance: Ref<any>) => {
    mapRef.current = instance
  }, [])

  const getGeolocation = () => {
    if (ymaps && mapRef.current) {
      setGeoStatus(true)
      ymaps.geolocation
        .get({
          provider: 'browser',
          autoReverseGeocode: true,
          mapStateAutoApply: true
        })
        .then((res: any) => {
          const components = res.geoObjects.get(0).properties.get('metaDataProperty')
            .GeocoderMetaData.Address.Components
          setQuery(`${components[0].name}, ${components[2].name}`)
          setCity(`${components[0].name}, ${components[2].name}`)
          setGeoStatus(false)
        })
        .catch(() => {
          setErrors({
            ...errors,
            geo: 'К сожалению, мы не смогли определиить ваше местоположение. Воспользуйтесь окном поиска.'
          })
          setGeoStatus(false)
        })
    }
  }

  const onLoad = async (ymaps: YMapsProps) => {
    setState({
      iconContentLayout: [
        ymaps.templateLayoutFactory.createClass(pinTemplate()),
        ymaps.templateLayoutFactory.createClass(pinTemplate(false, 1)),
        ymaps.templateLayoutFactory.createClass(pinTemplate(false, 2)),
        ymaps.templateLayoutFactory.createClass(pinTemplate(false, 3))
      ],
      chosenIconContentLayout: [
        ymaps.templateLayoutFactory.createClass(pinTemplate(true)),
        ymaps.templateLayoutFactory.createClass(pinTemplate(true, 1)),
        ymaps.templateLayoutFactory.createClass(pinTemplate(true, 2)),
        ymaps.templateLayoutFactory.createClass(pinTemplate(true, 3))
      ],
      clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(groupTemplate)
    })

    setYmaps(ymaps)
    setLoaded(true)
  }

  const setBoundsHandle = (points: Point[]) => {
    if (ymaps) {
      const services = getServices(points)

      setPoints(points)
      setServices(services)
      setChecked([true, ...new Array(services.length).fill(false)])
      if (points.length > 0) {
        if (city !== undefined) {
          setCity(city)
        }
        if (points.length === 1) {
          ;(mapRef.current as any).setBounds(ymaps.util.bounds.fromPoints(getArrayPoints(points)))
          ;(mapRef.current as any).setZoom(12)
        } else {
          setBounds(ymaps.util.bounds.fromPoints(getArrayPoints(points)))
        }
      } else {
        setBounds(ymaps.util.bounds.fromPoints(getArrayPoints(allPoints)))
      }
      setErrors({
        geo: '',
        points:
          points.length > 0
            ? ''
            : 'Нет дилеров, подходящих по вашему поиску. Попробуйте найти по-другому.'
      })
    }
  }

  useEffect(() => {
    if (ymaps && mapRef.current && allPoints.length > 0) {
      setPoint(undefined)
      setSearchedByName(false)
      if (!!city) {
        setGeoStatus(true)
        client
          .getPointsByCity(city, allPoints)
          .then((data) => {
            setBoundsHandle(data)
          })
          .finally(() => {
            setGeoStatus(false)
          })
      } else {
        setBoundsHandle(allPoints)
      }
    }
    // eslint-disable-next-line
  }, [city, ymaps])

  useEffect(() => {
    if (ymaps && loaded) {
      ;(mapRef.current as any).container.fitToViewport()
      const city = getCity()
      if (city && mapRef.current) {
        setQuery(city)
        setCity(city)
      } else {
        setBounds(ymaps.util.bounds.fromPoints(getArrayPoints(allPoints)))
      }
    }
    // eslint-disable-next-line
  }, [loaded, ymaps])

  return (
    <main className={`${css.main}`} style={{ display: loaded ? 'grid' : 'none' }}>
      <div className={sidebar.searchSection}>
        <div className={sidebar.radioBlock}>
          <p className={sidebar.radioTitle}>Искать по:</p>
          <div className={sidebar.radioBoxes}>
            <RadioBox text="Город">
              <input
                type="radio"
                name="search"
                value="city"
                checked={searchType === 'city'}
                onChange={() => {
                  setSearchType('city')
                  if (city !== undefined) {
                    setQuery(city)
                  }
                }}
              />
            </RadioBox>
            <RadioBox text="Название">
              <input
                type="radio"
                name="search"
                value="salon"
                checked={searchType === 'name'}
                onChange={() => {
                  setSearchType('name')
                  setQuery('')
                }}
              />
            </RadioBox>
          </div>
        </div>
        <SearchBlock
          query={query}
          setQuery={setQuery}
          setCity={setCity}
          getGeolocation={getGeolocation}
          status={geoStatus}
          cities={getCities(allPoints)}
          searchType={searchType}
          searchByName={() => {
            const points = getPointsByName(allPoints, query)
            setSearchedByName(true)
            setBoundsHandle(points)
          }}
        />
        {(!!errors.geo || !!errors.points) && <Error>{errors.geo || errors.points}</Error>}
        {services.length > 0 && (
          <ServicesBlock services={services} setChecked={setChecked} checked={checked} />
        )}
      </div>
      <div className={sidebar.servicesBlock}>
        {(!!point
          ? [point]
          : !!city || searchedByName || (!checked[0] && !!checked.length)
          ? getFilterByService(points, checked, services)
          : []
        ).map((item, index) => (
          <ServiceCard
            key={index}
            point={item}
            chosen={point && isSamePoint(point, item)}
            onClick={() => {
              if (!point) {
                setPoint(
                  point &&
                    isSamePoint(point, item) &&
                    getFilterByService(points, checked, services).length > 0
                    ? undefined
                    : item
                )
              }
            }}
          />
        ))}
      </div>
      <div className={css.mapContainer}>
        <YMaps query={{ apikey: Y_KEY, lang: 'ru_RU' }}>
          <Map
            width="100%"
            height="100%"
            state={{
              center: MOSCOW_COORDS,
              zoom: 5,
              controls: ['zoomControl'],
              behaviors: ['drag', 'dblClickZoom'],
              bounds,
              margin: [20, 20, 20, 20]
            }}
            onLoad={onLoad}
            options={{ suppressMapOpenBlock: true }}
            modules={[
              'templateLayoutFactory',
              'layout.ImageWithContent',
              'geolocation',
              'geocode',
              'suggest',
              'util.bounds',
              'control.ZoomControl'
            ]}
            instanceRef={setMapRef}
          >
            <Clusterer
              options={{
                clusterIcons: [
                  {
                    size: [32, 32],
                    offset: [-16, -32]
                  }
                ],
                clusterIconContentLayout
              }}
            >
              {allPoints.map(
                (item, itemIdx) =>
                  isVisiblePoint(
                    getFilterByService(points, checked, services),
                    item,
                    !!city || searchedByName || (!checked[0] && !!checked.length)
                  ) && (
                    <Placemark
                      defaultGeometry={[
                        parseFloat(item.geolocation.lat),
                        parseFloat(item.geolocation.lng)
                      ]}
                      key={`${'clusterIdx'}-${itemIdx}`}
                      onClick={() => {
                        setPoint(point && isSamePoint(point, item) ? undefined : item)
                      }}
                      options={{
                        iconLayout: 'default#imageWithContent',
                        iconImageSize: [30, 42],
                        iconImageHref: pin,
                        iconContentOffset: [-15, -42],
                        iconContentLayout:
                          point && isSamePoint(point, item)
                            ? chosenIconContentLayout[getPointTypeIndex(item.type)]
                            : iconContentLayout[getPointTypeIndex(item?.type)]
                      }}
                    />
                  )
              )}
            </Clusterer>
          </Map>
        </YMaps>
      </div>
    </main>
  )
}

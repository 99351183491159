import { Point } from '../types/point'

export const getCities = (points: Point[]): string[] => {
  const cities: string[] = []
  points.map((point) => {
    if (!cities.includes(point.city) && !point.city.includes('Кудрово')) {
      cities.push(point.city)
    }
  })

  return cities.map((city) => `Россия, ${city}`)
}

export const getPointsByName = (points: Point[], query: string): Point[] => {
  return points.filter((point) => point.name.toLowerCase().includes(query.toLowerCase()))
}

import React from 'react'

const Website = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM9.72293 5.3787C7.27884 6.21905 5.44643 8.37454 5.07089 11H7.85692C8.04702 9.00285 8.688 7.08165 9.72293 5.3787ZM14.277 5.37869C15.312 7.08164 15.953 9.00285 16.1431 11H18.9291C18.5536 8.37453 16.7211 6.21903 14.277 5.37869ZM14.1324 11C13.9115 9.03394 13.1754 7.16119 12 5.57179C10.8246 7.16119 10.0884 9.03394 9.86759 11H14.1324ZM9.86759 13H14.1324C13.9115 14.9661 13.1754 16.8388 12 18.4282C10.8246 16.8388 10.0884 14.9661 9.86759 13ZM7.85692 13H5.07089C5.44643 15.6255 7.27884 17.781 9.72293 18.6213C8.68801 16.9184 8.04702 14.9971 7.85692 13ZM14.277 18.6213C15.312 16.9184 15.953 14.9971 16.1431 13H18.9291C18.5536 15.6255 16.7211 17.781 14.277 18.6213Z"
      // fill="#606060"
    />
  </svg>
)

export default Website

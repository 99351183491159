import React from 'react'

import styles from './radioBox.module.css'

type Props = {
  children: JSX.Element
  text: string
}

export const RadioBox = ({ text, children }: Props): JSX.Element => {
  return (
    <label className={styles.radioLabel}>
      {children}
      <div />
      <span className={styles.radioText}>{text}</span>
    </label>
  )
}

import React from 'react'

const Parts = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 14.5C12.8284 14.5 13.5 13.8284 13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5Z"
      // fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C14.7614 18 17 15.7614 17 13C17 10.2386 14.7614 8 12 8C9.23858 8 7 10.2386 7 13C7 15.7614 9.23858 18 12 18ZM12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16Z"
      // fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5C15 4.50393 15.0002 4.50818 15.0006 4.51263C18.4946 5.74796 21 9.08013 21 13C21 13.7316 20.9125 14.4441 20.7469 15.127C20.7406 15.1533 20.732 15.2404 20.8065 15.4095C20.8803 15.577 21.0093 15.7608 21.1663 15.9247C21.6818 16.4626 22 17.1952 22 18C22 19.6569 20.6569 21 19 21C18.5499 21 18.1203 20.9002 17.7345 20.7206C17.5293 20.625 17.3146 20.5626 17.1336 20.5471C16.9512 20.5315 16.8731 20.5678 16.8511 20.5818C15.4507 21.4796 13.7846 22 12 22C10.2154 22 8.54934 21.4796 7.14887 20.5818C7.12694 20.5678 7.04882 20.5315 6.86635 20.5471C6.68536 20.5626 6.47074 20.625 6.26545 20.7206C5.87971 20.9002 5.45013 21 5 21C3.34315 21 2 19.6569 2 18C2 17.1952 2.31824 16.4626 2.83368 15.9247C2.99065 15.7608 3.11968 15.577 3.1935 15.4095C3.26803 15.2404 3.25941 15.1533 3.25306 15.127C3.0875 14.4441 3 13.7316 3 13C3 9.08013 5.50536 5.74796 8.99936 4.51263C8.99979 4.50818 9 4.50393 9 4.5ZM12 3.5C11.4477 3.5 11 3.94772 11 4.5C11 5.15565 10.6572 6.06116 9.74819 6.36978C6.98562 7.30769 5 9.92326 5 13C5 13.5716 5.06831 14.126 5.19676 14.6559C5.33825 15.2395 5.21213 15.7884 5.02364 16.2161C4.83445 16.6453 4.55496 17.0191 4.27777 17.3083C4.10476 17.4889 4 17.7312 4 18C4 18.5523 4.44772 19 5 19C5.15249 19 5.2944 18.9665 5.42117 18.9075C5.78445 18.7383 6.22842 18.5944 6.6959 18.5544C7.16188 18.5146 7.72209 18.5737 8.22819 18.8981C9.31618 19.5955 10.6093 20 12 20C13.3907 20 14.6838 19.5955 15.7718 18.8981C16.2779 18.5737 16.8381 18.5146 17.3041 18.5544C17.7716 18.5944 18.2156 18.7383 18.5788 18.9075C18.7056 18.9665 18.8475 19 19 19C19.5523 19 20 18.5523 20 18C20 17.7312 19.8952 17.4889 19.7222 17.3083C19.445 17.0191 19.1655 16.6453 18.9764 16.2161C18.7879 15.7884 18.6617 15.2395 18.8032 14.6559C18.9317 14.126 19 13.5716 19 13C19 9.92326 17.0144 7.30769 14.2518 6.36978C13.3428 6.06117 13 5.15565 13 4.5C13 3.94772 12.5523 3.5 12 3.5Z"
      // fill="#606060"
    />
  </svg>
)

export default Parts

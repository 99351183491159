import axios from 'axios'
import axiosRetry from 'axios-retry'

import { API_URL, BRAND, Y_KEY } from '../config'
import { Client } from '../types/api'
import { PointResponse } from '../types/response'
import { getPointType } from '../utils/getPointType'

const api = axios.create({ baseURL: API_URL })

axiosRetry(api, {
  retries: 2,
  retryDelay: (retryCount) => {
    return axiosRetry.exponentialDelay(retryCount)
  },
  retryCondition: (e) =>
    String(e.response?.status)[0] === '4' || String(e.response?.status)[0] === '5'
})

export const client: Client = {
  geocoder: (query) =>
    axios.get(`https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${Y_KEY}&geocode=${query}`),
  getPoints: async () => {
    try {
      console.log('try')
      const res = await api.get(
        `/points?filters[$and][0][brands][name][$eq]=${BRAND}&pagination[limit]=100&populate=geolocation,services,city`
      )
      const data: PointResponse = res.data
      return data.data
        .map((point) => ({
          ...point.attributes,
          services: point.attributes.services.data.map((item) => ({
            name: item.attributes.name,
            id: item.id,
            sort: item.attributes.sort
          })),
          city: point.attributes.city.data.attributes.name,
          type: getPointType(point.attributes.services)
        }))
        .filter((point) => {
          if (point.geolocation.lng.includes(',') || point.geolocation.lat.includes(',')) {
            console.warn(
              `🔴 not correct lat or lng for ${point.name}: ${point.geolocation.lng} ${point.geolocation.lat} in ${point.city}`
            )
          }
          return !point.geolocation.lng.includes(',') && !point.geolocation.lat.includes(',')
        })
    } catch (e) {
      console.error(e)
      return []
    }
  },
  getPointsByCity: async (query, points) => {
    const queryText = query.includes('Ленинградская область') ? 'Санкт-Петербург' : query
    return points.filter(
      (point) =>
        queryText.includes(point.city) ||
        (queryText.includes('Санкт-Петербург') && point.city.includes('Кудрово'))
    )
  }
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import Chevron from '../../icons/Chevron'
import Cross from '../../icons/Cross'
import Geo from './../../icons/Geo'
import Loading from './../Loader'
import styles from './searchBlock.module.css'

export const SearchBlock = ({
  getGeolocation,
  query,
  setQuery,
  setCity,
  status,
  cities,
  searchType,
  searchByName
}: {
  query: string
  setQuery: (v: string) => void
  getGeolocation: () => void
  setCity: (v: string) => void
  status: boolean
  cities: string[]
  searchType: string
  searchByName: () => void
}): JSX.Element => {
  const [openList, setOpenList] = useState(false)
  const ref = useRef(null)

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && !(ref.current as any).contains(e.target)) {
      setOpenList(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  return (
    <div className={styles.searchWrapper}>
      <button className={styles.gpsButton} onClick={getGeolocation}>
        <div className={styles.gpsImage}>{status ? <Loading /> : <Geo />}</div>
      </button>
      <div className={styles.searchBarWrapper} ref={ref}>
        <div className={styles.inputWrapper}>
          <input
            id="search"
            className={styles.searchInput}
            name="search"
            type="text"
            placeholder={`Поиск ${searchType === 'city' ? 'города' : 'по названию'}`}
            value={query}
            autoComplete="off"
            onChange={(e) => {
              setQuery(e.target.value)
              setOpenList(e.target.value.length > 0)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && searchType === 'name') {
                searchByName()
              }
            }}
          />
          {query && query.length > 0 && (
            <button
              className={styles.removeText}
              onClick={() => {
                setQuery('')
              }}
            >
              <Cross />
            </button>
          )}
        </div>
        <button
          className={`${styles.listButton}`}
          onClick={() => {
            if (searchType === 'city') {
              setOpenList(!openList)
            } else {
              searchByName()
            }
          }}
        >
          <div
            className={`${styles.optionsButtonImage} ${openList && styles.rotate}`}
            style={searchType === 'name' ? { transform: 'rotate(-90deg)' } : undefined}
          >
            <Chevron />
          </div>
        </button>
        {openList && searchType === 'city' && (
          <div className={styles.resultsList}>
            {cities
              .filter((item) => item.toLowerCase().includes(query.toLowerCase()))
              .map((city) => (
                <div
                  className={styles.listCard}
                  key={city}
                  onClick={() => {
                    setQuery(city)
                    setCity(city)
                    setOpenList(false)
                  }}
                >
                  <span>{city}</span>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'

const ServiceIcon = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0585 5.00166C14.7777 4.99362 14.4955 5.01476 14.2164 5.06562C13.4062 5.21324 12.6602 5.60427 12.0778 6.18661C11.4955 6.76894 11.1045 7.51491 10.9569 8.32511C10.8092 9.13532 10.912 9.97127 11.2516 10.7216C11.4232 11.1008 11.3419 11.5466 11.0476 11.8409L5.23019 17.6584C5.0828 17.8058 5 18.0057 5 18.2141C5 18.4225 5.0828 18.6224 5.23019 18.7698C5.37757 18.9172 5.57747 19 5.78591 19C5.99434 19 6.19424 18.9172 6.34163 18.7698L12.1591 12.9524C12.4534 12.6581 12.8992 12.5768 13.2784 12.7484C14.0287 13.088 14.8647 13.1908 15.6749 13.0431C16.4851 12.8955 17.2311 12.5045 17.8134 11.9222C18.3957 11.3398 18.7868 10.5938 18.9344 9.78365C18.9852 9.50448 19.0064 9.22225 18.9983 8.94153L17.0769 10.863C16.7326 11.2005 16.2696 11.3895 15.7875 11.3895C15.3054 11.3895 14.8425 11.2005 14.4982 10.863L14.491 10.856L13.137 9.50185C12.7995 9.15755 12.6105 8.69462 12.6105 8.21249C12.6105 7.73037 12.7995 7.26747 13.137 6.92317L13.144 6.91603L15.0585 5.00166ZM13.8579 3.09802C15.068 2.87752 16.3167 3.03107 17.4373 3.53821C17.7364 3.67355 17.95 3.94706 18.0089 4.27001C18.0677 4.59297 17.9643 4.92425 17.7322 5.15637L14.6761 8.21249L15.7875 9.32394L18.8436 6.26782C19.0758 6.03569 19.407 5.93228 19.73 5.99112C20.0529 6.04996 20.3265 6.26357 20.4618 6.56265C20.9689 7.68334 21.1225 8.93197 20.902 10.1421C20.6815 11.3523 20.0974 12.4666 19.2276 13.3364C18.3578 14.2062 17.2436 14.7902 16.0334 15.0107C15.0566 15.1887 14.0548 15.123 13.1155 14.8244L7.75584 20.184C7.23338 20.7065 6.52478 21 5.78591 21C5.04704 21 4.33843 20.7065 3.81597 20.184C3.29351 19.6616 3 18.953 3 18.2141C3 17.4752 3.29351 16.7666 3.81597 16.2442L9.1756 10.8845C8.87699 9.94516 8.81129 8.94336 8.98926 7.96661C9.20975 6.75644 9.79382 5.6422 10.6636 4.77239C11.5334 3.90258 12.6477 3.31851 13.8579 3.09802Z"
      // fill="#606060"
    />
  </svg>
)

export default ServiceIcon

import React from 'react'

const Geo = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3H13V5.07089C16.0657 5.5094 18.4906 7.93431 18.9291 11H21V13H18.9291C18.4906 16.0657 16.0657 18.4906 13 18.9291V21H11V18.9291C7.93431 18.4906 5.5094 16.0657 5.07089 13H3V11H5.07089C5.5094 7.93431 7.93431 5.5094 11 5.07089V3ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
      fill="#fff"
    />
  </svg>
)

export default Geo

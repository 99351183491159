import Corporate from '../ui/icons/Corporate'
import OnlineIcon from '../ui/icons/Online'
import Parts from '../ui/icons/Parts'
import RuleIcon from '../ui/icons/RuleIcon'
import ServiceIcon from '../ui/icons/ServiceIcon'
import TradeIn from '../ui/icons/TradeIn'
import Cross from '../ui/icons/UsedCars'

export const BRAND = 'Fiat'

export const Y_KEY = '3e8b937f-1a67-49b2-8024-aa53808c9502'

export const API_URL = 'https://credit.peugeot-rus.ru/api'

export const MOSCOW_COORDS = [55.7558, 37.6173]

export const SERVICES_ICONS = [
  RuleIcon(),
  Corporate(),
  Cross(),
  OnlineIcon(),
  TradeIn(),
  ServiceIcon(),
  Parts()
]

export const POINT_TYPES = [
  'Официальный дилер',
  `${BRAND} Professional`,
  'Официальный сервисный центр',
  'Авторизованный партнер'
]

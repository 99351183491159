import { Point } from '../types/point'
import { Service } from '../types/service'

export const getFilterByService = (
  points: Point[],
  checked: boolean[],
  services: Service[]
): Point[] => {
  if (checked[0]) {
    return points
  }
  const arr = services.map((item) => item.name)
  return points.filter(
    (point) =>
      !!point.services.find((service) =>
        arr.filter((s, idx) => checked[idx]).includes(service.name)
      )
  )
}

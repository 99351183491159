import React from 'react'

import { Service } from '../../../types/service'
import Cross from '../../icons/Cross'
import styles from './styles.module.css'

export const SelectedService = ({
  service,
  onClick
}: {
  service: Service
  onClick: () => void
}): JSX.Element => {
  return (
    <div className={styles.selectedService}>
      <span>
        {service.name} <span>{service.count}</span>
      </span>
      <button onClick={onClick}>
        <Cross />
      </button>
    </div>
  )
}

import { useEffect, useState } from 'react'

import { client } from '../api'
import { Point } from '../types/point'

export const useAllPoints = (): Point[] => {
  const [state, setState] = useState<Point[]>([])

  useEffect(() => {
    client.getPoints().then((res) => {
      setState(res)
    })
  }, [])

  return state
}

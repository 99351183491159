import { POINT_TYPES } from '../config'
import { ServiceMeta } from '../types/service'

const checkPoint = (services: ServiceMeta, name: string): boolean =>
  !!services.data.find((item) => item.attributes.name.toLowerCase().includes(name))

export const getPointType = (services: ServiceMeta): string | undefined =>
  checkPoint(services, 'новых автомобилей') && checkPoint(services, 'сервис')
    ? POINT_TYPES[0]
    : checkPoint(services, 'коммерческих') && checkPoint(services, 'сервис')
    ? POINT_TYPES[1]
    : checkPoint(services, 'сервис')
    ? POINT_TYPES[2]
    : checkPoint(services, 'запасных')
    ? POINT_TYPES[3]
    : undefined

import React from 'react'

const RuleIcon = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.1106 7.09242 3 12 3C16.9706 3 21 7.02944 21 12ZM13 18.9291C16.258 18.4631 18.7923 15.7536 18.9879 12.4157C18.8666 12.3914 18.712 12.3608 18.5296 12.3258C18.049 12.2335 17.3766 12.1104 16.6107 11.9873C16.5347 11.9751 16.4579 11.9629 16.3803 11.9508C15.1144 12.8309 13.8696 14.0971 13 15.7419V18.9291ZM6.88926 11.9873C7.09714 11.9539 7.3109 11.9207 7.5287 11.8882C8.82574 12.7705 10.1096 14.0577 11 15.7419V18.9291C7.71047 18.4586 5.15871 15.701 5.00713 12.3188C5.485 12.2273 6.14275 12.1073 6.88926 11.9873ZM6.572 10.0127C6.0753 10.0925 5.61704 10.1722 5.22205 10.2441C6.00116 7.22796 8.7404 5 12 5C15.2947 5 18.0577 7.27615 18.8024 10.3417C18.3159 10.2492 17.6642 10.1309 16.928 10.0127C15.3625 9.76114 13.3596 9.5 11.75 9.5C10.1404 9.5 8.1375 9.76114 6.572 10.0127Z"
      // fill="#606060"
    />
  </svg>
)

export default RuleIcon

import React from 'react'

const UsedCars = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2071 3.70711C20.5976 3.31658 20.5976 2.68342 20.2071 2.29289C19.8166 1.90237 19.1834 1.90237 18.7929 2.29289L16 5.08579L14.7071 3.79289C14.3166 3.40237 13.6834 3.40237 13.2929 3.79289C12.9024 4.18342 12.9024 4.81658 13.2929 5.20711L15.2929 7.20711C15.6834 7.59763 16.3166 7.59763 16.7071 7.20711L20.2071 3.70711ZM21.0002 12C21.0002 16.9706 16.9708 21 12.0002 21C7.02964 21 3.00021 16.9706 3.00021 12C3.00021 11.8742 3.00279 11.7489 3.0079 11.6243C2.99187 11.4975 3.00061 11.3723 3.03067 11.2541C3.35522 7.29803 6.23837 4.06667 10.0221 3.21808C10.5577 3.09795 11.0522 3.49468 11.1166 4.0398C11.1817 4.59144 10.7812 5.08329 10.2434 5.22228C7.78879 5.8567 5.85635 7.78937 5.22225 10.2441C5.61724 10.1722 6.0755 10.0925 6.57221 10.0127C8.13771 9.76115 10.1406 9.5 11.7502 9.5C13.3598 9.5 15.3627 9.76115 16.9282 10.0127C17.6644 10.1309 18.3161 10.2492 18.8026 10.3417C18.763 10.1784 18.7176 10.0174 18.6667 9.85877C18.5072 9.3619 18.6828 8.79508 19.1351 8.53485C19.6345 8.24749 20.2867 8.4348 20.4801 8.97756C20.8169 9.92223 21.0002 10.9397 21.0002 12ZM18.9881 12.4158C18.7926 15.7536 16.2582 18.4631 13.0002 18.9291V15.7419C13.8698 14.0971 15.1146 12.8309 16.3805 11.9508C16.4581 11.9629 16.5349 11.9751 16.6109 11.9873C17.3768 12.1104 18.0492 12.2335 18.5298 12.3258C18.7122 12.3608 18.8668 12.3914 18.9881 12.4158ZM7.52891 11.8882C7.3111 11.9207 7.09734 11.9539 6.88946 11.9873C6.14296 12.1073 5.48521 12.2273 5.00733 12.3188C5.15892 15.701 7.71068 18.4586 11.0002 18.9291V15.7419C10.1098 14.0577 8.82595 12.7705 7.52891 11.8882Z"
      // fill="#606060"
    />
  </svg>
)

export default UsedCars

import React from 'react'

const Corporate = (): JSX.Element => (
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.910059 6.42849L6.65716 0.847757C7.21703 0.304099 7.9667 0 8.7471 0H21C22.6569 0 24 1.34315 24 3V10C24 11.6569 22.6569 13 21 13H20.8293C20.4175 14.1652 19.3062 15 18 15C16.6938 15 15.5825 14.1652 15.1707 13H9.82929C9.41746 14.1652 8.30622 15 7 15C5.69378 15 4.58254 14.1652 4.17071 13H3C1.34315 13 0 11.6569 0 10V8.58074C0 7.76981 0.328289 6.99342 0.910059 6.42849ZM21 11H20.8293C20.4175 9.83481 19.3062 9 18 9C16.6938 9 15.5825 9.83481 15.1707 11H9.82929C9.41746 9.83481 8.30622 9 7 9C5.69378 9 4.58254 9.83481 4.17071 11H3C2.44772 11 2 10.5523 2 10V8.58074C2 8.37105 2.06585 8.1683 2.1859 8H7C8.10457 8 9 7.10457 9 6V2H21C21.5523 2 22 2.44772 22 3V10C22 10.5523 21.5523 11 21 11ZM7 3.30263L4.22222 6H7V3.30263ZM8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12ZM18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z"
    />
  </svg>
)

export default Corporate

import React from 'react'

const TradeIn = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.98419 9.31417C5.09848 6.07647 8.17112 3.75 11.7873 3.75C14.7591 3.75 17.3638 5.32121 18.8161 7.67816L19.0089 6.26126C19.0834 5.71402 19.5874 5.33075 20.1346 5.40521C20.6819 5.47967 21.0651 5.98366 20.9907 6.5309L20.5115 10.0523C20.4371 10.5996 19.9331 10.9828 19.3858 10.9084L15.7746 10.417C15.2274 10.3425 14.8441 9.83856 14.9186 9.29131C14.993 8.74407 15.497 8.3608 16.0443 8.43526L17.1126 8.58063C15.9868 6.83102 14.0223 5.67227 11.7873 5.67227C9.08552 5.67227 6.77911 7.36548 5.87154 9.74882C5.68102 10.2491 5.15843 10.5826 4.63116 10.4465C4.1312 10.3176 3.81378 9.80932 3.98419 9.31417Z"
      // fill="#606060"
    />
    <path
      d="M6.43663 15.3802L7.79209 15.5646C8.33933 15.6391 8.84332 15.2558 8.91778 14.7086C8.99224 14.1613 8.60897 13.6573 8.06173 13.5829L4.4505 13.0915C3.90326 13.0171 3.39927 13.4003 3.32481 13.9476L2.84567 17.469C2.77122 18.0162 3.15448 18.5202 3.70172 18.5947C4.24897 18.6691 4.75296 18.2859 4.82741 17.7386L4.97482 16.6553C6.461 18.8261 8.95776 20.2505 11.7873 20.2505C15.4034 20.2505 18.4761 17.924 19.5904 14.6863C19.7608 14.1911 19.4434 13.6829 18.9434 13.5539C18.4161 13.4179 17.8935 13.7514 17.703 14.2516C16.7955 16.635 14.489 18.3282 11.7873 18.3282C9.5354 18.3282 7.55817 17.1519 6.43663 15.3802Z"
      // fill="#606060"
    />
  </svg>
)

export default TradeIn

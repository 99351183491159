import { Point } from '../types/point'
import { Service } from '../types/service'

export const getServices = (points: Point[]): Service[] => {
  const services: Service[] = [{ name: 'Все дилеры', count: points.length, sort: 0 }]
  points.map((point) => {
    point.services.map((service) => {
      const idx = services.findIndex((item) => item.name === service.name)
      if (idx === -1) {
        services.push({ name: service.name, count: 1, sort: service.sort })
      } else {
        services[idx].count += 1
      }
    })
  })
  return services
}

export const isAllServices = (service: Service) => service.name === 'Все дилеры'

export const getSelectedServices = (services: Service[], checked: boolean[]): Service[] =>
  services.filter((service, idx) => checked[idx] && !isAllServices(service))

import React from 'react'

import styles from './checkBoxButton.module.css'

type Props = {
  children: JSX.Element
  text: string
  count: number
}

export const CheckBoxButton = ({ text, count, children }: Props): JSX.Element => {
  return (
    <label className={styles.checkboxButtonLabel}>
      {children}
      <div />
      <span className={styles.buttonText}>
        {text} <span>{count}</span>
      </span>
    </label>
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Service } from '../../../types/service'
import { getSelectedServices } from '../../../utils/getServices'
import Chevron from '../../icons/Chevron'
import { CheckBoxButton } from '../CheckBoxButton'
import { SelectedService } from '../SelectedService'
import styles from './servicesBlock.module.css'

export const ServicesBlock = ({
  services,
  setChecked,
  checked
}: {
  services: Service[]
  checked: boolean[]
  setChecked: (v: boolean[]) => void
}): JSX.Element => {
  const [openList, setOpenList] = useState(false)
  const ref = useRef(null)
  const selectedServices = getSelectedServices(services, checked)

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && !(ref.current as any).contains(e.target)) {
      setOpenList(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  return (
    <div>
      <div className={styles.servicesWrapper} ref={ref}>
        <button className={styles.servicesButton} onClick={() => setOpenList(!openList)}>
          <p className={styles.buttonText}>Фильтровать по услугам</p>
          <div className={`${styles.buttonImage} ${openList && styles.rotate}`}>
            <Chevron />
          </div>
        </button>
        {openList && (
          <div className={styles.servicesList}>
            {services
              .sort((a, b) => a.sort - b.sort)
              .map((service, idx) => (
                <CheckBoxButton key={service.name} text={service.name} count={service.count}>
                  <input
                    type="checkbox"
                    name="services"
                    value={service.name}
                    checked={checked[idx]}
                    onChange={() => {
                      if (idx === 0) {
                        setChecked(checked.map((i, id) => id === 0))
                      } else {
                        const arr = [...checked]
                        arr[idx] = !checked[idx]
                        arr[0] = false
                        setChecked(
                          arr.includes(true)
                            ? arr
                            : [true, ...new Array(services.length).fill(false)]
                        )
                      }
                    }}
                  />
                </CheckBoxButton>
              ))}
          </div>
        )}
      </div>
      {selectedServices.length > 0 && (
        <div className={styles.servicesSelected}>
          {selectedServices.map((service, idx) => (
            <SelectedService
              key={idx}
              onClick={() => {
                const arr = [...checked]
                arr[services.findIndex((s) => s.name === service.name)] = false
                if (selectedServices.length === 1) {
                  setChecked(checked.map((i, id) => id === 0))
                } else {
                  setChecked(arr)
                }
              }}
              service={service}
            />
          ))}
        </div>
      )}
    </div>
  )
}

import React from 'react'

import { Point } from '../../../types/point'
import { getServiceIcon } from '../../../utils/getServiceIcon'
import { getWebsiteLink } from '../../../utils/getWebsiteLink'
import LocationIcon from '../../icons/Location'
import PhoneIcon from '../../icons/PhoneIcon'
import Pin from '../../icons/Pin'
import TimeIcon from '../../icons/Time'
import Website from '../../icons/Website'
import styles from './serviceCard.module.css'

export const ServiceCard = ({
  point,
  chosen,
  onClick
}: {
  point: Point
  onClick: () => void
  chosen?: boolean
}): JSX.Element => {
  const schedule = point.schedule ? point.schedule.split('\\n') : undefined

  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      <div className={styles.cardHeader}>
        <div className={styles.pinImage}>
          <Pin selected={chosen} type={point.type} />
        </div>
        <div className={styles.nameBlock}>
          <p className={styles.serviceName}>{point.name}</p>
          <span className={styles.nameExtra}>{point.type}</span>
        </div>
      </div>
      <div className={styles.informationBlock}>
        {point.address && (
          <div className={styles.informationItem}>
            <div className={styles.informationImage}>
              <LocationIcon />
            </div>
            <p className={styles.infoText}>{point.address.replace('\\n', '\n')}</p>
          </div>
        )}
        {point.phone && (
          <div className={styles.informationItem}>
            <a
              className={styles.informationImage}
              href={`tel:${point.phone}`}
              target="_blank"
              rel="noreferrer"
            >
              <PhoneIcon />
            </a>
            <p className={styles.infoText}>{point.phone}</p>
          </div>
        )}
        {point.website && (
          <div className={styles.informationItem}>
            <div className={styles.informationImage}>
              <Website />
            </div>
            <a href={point.website} target="_blank" className={styles.infoLink} rel="noreferrer">
              {getWebsiteLink(point.website)}
            </a>
          </div>
        )}
        {point.secondWebsite && (
          <div className={styles.informationItem}>
            <div className={styles.informationImage}>
              <Website />
            </div>
            <a
              href={point.secondWebsite}
              target="_blank"
              className={styles.infoLink}
              rel="noreferrer"
            >
              {getWebsiteLink(point.secondWebsite)}
            </a>
          </div>
        )}
        {schedule && (
          <div className={styles.informationItem}>
            <div className={styles.informationImage}>
              <TimeIcon />
            </div>
            <p className={styles.infoText}>
              {schedule.map((item, idx) => (
                <span key={idx}>
                  {item}
                  {idx + 1 !== schedule?.length && <br />}
                </span>
              ))}
            </p>
          </div>
        )}
      </div>
      <p className={styles.serviseText}>Предоставляемые услуги:</p>
      <div className={styles.servivesList}>
        {point.services
          .sort((a, b) => a.sort - b.sort)
          .map((service) => (
            <div key={service.name} className={styles.informationItem}>
              <div className={styles.informationImage}>{getServiceIcon(service.id)}</div>
              <p className={styles.infoText}>{service.name}</p>
            </div>
          ))}
      </div>
      {point.callLink && (
        <a className={`btn black-button`} href={point.callLink} target="_blank" rel="noreferrer">
          <span>Заказать звонок</span>
        </a>
      )}
      {point.serviceLink && (
        <a className={`btn white-button`} href={point.serviceLink} target="_blank" rel="noreferrer">
          <span>Заявка на сервис</span>
        </a>
      )}
    </div>
  )
}
